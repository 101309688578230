// ProtectedRoute.js
import React from 'react';
import {PropTypes} from 'prop-types';
import {Navigate} from 'react-router-dom';
import {useAuth} from './AuthContext';

const ProtectedRoute = ({children}) => {
  const {isAuthenticated} = useAuth();

  return isAuthenticated ? children : <Navigate to="/login" />;
};


export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
