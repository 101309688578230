import React from 'react';
import {Link} from 'react-router-dom';

const PageNotFound = () => {
  return (
    <>
      <div className="p-5 text-center">
        <img src="404.svg" className="img-fluid col-md-4" />
        <div className="text-center pb-5">
          <h2 className="fw-bold text-white mt-4">Oh no! Where did you go?</h2>
          <p className="mb-4">
            We can`t seem to find the page you were looking for.
          </p>
          <Link
            to="/"
            className="btn btn-primary rounded-pill py-3 px-4 shadow-sm"
          >
            <span className="px-3">Go back to safety</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
