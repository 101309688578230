import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {LoadingSpinner} from './Components/Common/Functions';
import {useAuth} from './AuthContext';
import PageNotFound from './404';
import ProtectedRoute from './ProtectedRoute';
import 'react-toastify/dist/ReactToastify.css';
import './bootstrap.min.css';
import './App.css';
import './index.css';

const ErrorBoundary = React.lazy(() => import('./ErrorBoundary'));
const Sidebar = React.lazy(() => import('./Sidebar'));
const Footer = React.lazy(() => import('./Footer'));
const Header = React.lazy(() => import('./Header'));
const Faq = React.lazy(() => import('./Components/Pages/Faq'));
const About = React.lazy(() => import('./Components/Pages/About'));
const Contact = React.lazy(() => import('./Components/Pages/Contact'));
const Login = React.lazy(() => import('./Components/Authenticate/Login'));
const MemoizedLogin= React.memo( Login );
const VerifyEmail = React.lazy(() => import('./Components/Authenticate/Verifyemail'));
const MemoizedVerifyEmail = React.memo( VerifyEmail );
const VerifyCell = React.lazy(() => import('./Components/Authenticate/Verifycell'));
const MemoizedVerifyCell = React.memo( VerifyCell );
const Reset = React.lazy(() => import('./Components/Authenticate/Reset'));
const MemoizedReset = React.memo(Reset );
const Register = React.lazy(() => import('./Components/Authenticate/Register'));
const MemoizedRegister = React.memo( Register );
const Password = React.lazy(() => import('./Components/Authenticate/Password'));
const MemoizedPassword = React.memo(Password);
const EditProfile = React.lazy(() => import('./Components/User/EditProfile'));
const MemoizedEditProfile = React.memo(EditProfile);
const CookieConsentPopup = React.lazy(() => import('./CookieConsentPopup'));
const MemoizedCookieConsentPopup= React.memo(CookieConsentPopup);
const Meeting = React.lazy(() => import('./meeting/meeting'));
const MemoizedMeeting = React.memo( Meeting);
const People = React.lazy(() => import('./TabPeople'));
const MemoizedPeople = React.memo(People );
const Circle = React.lazy(() => import('./TabCircle'));
const MemoizedCircle = React.memo( Circle );
const Followers = React.lazy(() => import('./Components/User/TabFollowers'));
const MemoizedFollowers = React.memo(Followers );
const Following = React.lazy(() => import('./Components/User/TabFollowing'));
const MemoizedFollowing = React.memo( Following );
const Feeds = React.lazy(() => import('./TabFeed'));
const MemoizedFeeds = React.memo(Feeds);
const Bookmark = React.lazy(() => import('./TabBookmark'));
const MemoizedBookmark = React.memo( Bookmark );
const Trending = React.lazy(() => import('./TabTrends'));
const MemoizedTrending = React.memo(Trending);
const Profile = React.lazy(() => import('./Components/User/Profile'));
const MemoizedProfile = React.memo( Profile);
const PostDetails = React.lazy(() => import('./Components/Post/PostDetails'));
const MemoizedPostDetails = React.memo( PostDetails );
const Kanban = React.lazy(() => import('./Components/Kanban/Kanban'));
const MemoizedKanban = React.memo( Kanban);
const HashtagSearch =React.lazy(() => import('./HashtagSearch'));
const MemoizedHashtagSearch= React.memo(HashtagSearch);

const App = () => {
  const {currentuser, isAuthenticated} = useAuth();
  // const minCount = 10;

  const filterFeed = {
        active: true,
        ispublic: true,
        parent_post_id: {$eq: null},
      };


  const filterBookmark= {
    _id: {$in: currentuser?.bookmarks},
    active: true,
    ispublic: true,

  };


  return (
    <>
      <Header />
      <div className="py-4">
        <div className="container">
          <div className="row position-relative g-0">
            <Router>
              <ErrorBoundary>
                <Suspense fallback={<LoadingSpinner />}>
                  <Routes>
                    <Route
                      path="/login"
                      element={

                        <MemoizedLogin />

                      }
                    />
                    <Route
                      path="/register"
                      element={

                        <MemoizedRegister />

                      }
                    />
                    <Route
                      path="/reset"
                      element={

                        <MemoizedReset />

                      }
                    />
                    <Route
                      path="/password"
                      element={

                        <MemoizedPassword />

                      }
                    />


                    <Route
                      path="/"
                      element={

                        <ProtectedRoute>

                          <MemoizedFeeds filter={filterFeed} />

                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/contact"
                      element={

                        <Contact />

                      }
                    />
                    <Route
                      path="/about"
                      element={

                        <About />

                      }
                    />
                    <Route
                      path="/faq"
                      element={

                        <Faq />

                      }
                    />

                    <Route
                      path="/verifyemail/:code"
                      element={

                        <MemoizedVerifyEmail />

                      }
                    />
                    <Route
                      path="/verifycell"
                      element={

                        <MemoizedVerifyCell />

                      }
                    />
                    <Route
                      path="/feed"
                      element={
                        <ProtectedRoute>

                          <MemoizedFeeds filter={filterFeed} />

                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/bookmark"
                      element={
                        <ProtectedRoute>

                          <MemoizedBookmark filter={ filterBookmark} />

                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/explore"
                      element={
                        <ProtectedRoute>

                          <MemoizedTrending />

                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/people"
                      element={
                        <ProtectedRoute>

                          <MemoizedPeople filter={{active: true}} />

                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/circle"
                      element={
                        <ProtectedRoute>

                          <MemoizedCircle
                            filter={{
                              active: true,
                              _id: {$in: currentuser?.circle},
                            }}
                          />

                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/@/:userId"
                      element={

                        <MemoizedProfile />

                      }
                    />
                    <Route
                      path="/kanban"
                      element={
                        <ProtectedRoute>

                          <MemoizedKanban />

                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/search/:q"
                      element={


                        <MemoizedHashtagSearch />


                      }
                    />


                    <Route
                      path="/editprofile"
                      element={
                        <ProtectedRoute>

                          <MemoizedEditProfile />

                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/:userId/followers"
                      element={
                        <ProtectedRoute>

                          <MemoizedFollowers
                            filter={{
                              active: true,
                              _id: {$in: currentuser?.followers},
                            }}
                          />

                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/:userId/following"
                      element={
                        <ProtectedRoute>

                          <MemoizedFollowing
                            filter={{
                              active: true,
                              _id: {$in: currentuser?.following},
                            }}
                          />

                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/idea/:postId"
                      element={
                        <ProtectedRoute>

                          <MemoizedPostDetails />

                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/meeting/:receiver"
                      element={
                        <ProtectedRoute>

                          <MemoizedMeeting />

                        </ProtectedRoute>
                      }
                    />


                    <Route path="/*" element={<PageNotFound />} />

                  </Routes>

                  <ToastContainer
                    limit={1}

                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />

                  <ToastContainer />
                </Suspense>
              </ErrorBoundary>

              {isAuthenticated && <Sidebar />}
            </Router>
          </div>
        </div>
      </div>


      <Footer />

      <MemoizedCookieConsentPopup />
      <div
        className="modal fade bg-glass"
        id="postModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-4 shadow-sm p-4 border-0 bg-brown-gradient">
            <div className="modal-header d-flex align-items-center justify-content-start border-0 p-0 mb-3">
              <a href="#" className="text-white text-decoration-none material-icons"
                data-bs-dismiss="modal">arrow_back_ios_new</a>
              <h5 className="modal-title text-primary ms-3 ln-0" id="staticBackdropLabel">
                <span className="material-icons md-32">account_circle</span></h5>
            </div>
            <div className="modal-body p-0 mb-3">
              <div className="form-floating rounded-5 bg-glass">
                <textarea
                  className="form-control border-0 shadow-sm"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                >

                </textarea>
                <label htmlFor="floatingTextarea2" className="h6 text-muted mb-0">What&apos;s on your mind...</label>
              </div>
            </div>
            <div className="modal-footer justify-content-start px-1 py-1 bg-glass shadow-sm rounded-5">
              <div className="rounded-4 m-0 px-3 py-2 d-flex align-items-center justify-content-between w-75">
                <span href="#" className="text-muted text-decoration-none material-icons">insert_link</span>
                <span href="#" className="text-muted text-decoration-none material-icons">image</span>
                <span href="#" className="text-muted text-decoration-none material-icons">smart_display</span>
                <span className="text-muted">0/500</span>
              </div>
              <div className="ms-auto m-0">
                <a data-bs-dismiss="modal"
                  href="#"
                  className="btn btn-primary rounded-5 fw-bold px-3 py-2 fs-6 mb-0 d-flex align-items-center">
                  <span className="material-icons me-2 md-16">send</span>Post</a>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default App;
