import axios from 'axios';
import React from 'react';
import numeral from 'numeral';
import {toast} from 'react-toastify';
const axiosClient = axios.create();
axiosClient.defaults.baseURL = `$process.env{}/api/`;
axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};
/**
 *
 */

// Function to replace the image with a placeholder on error
export async function replaceImageWithPlaceholder(image) {
  // Hide the original image
  image.style.display = 'none';

  // Show the placeholder image
  const placeholder = document.getElementById('placeholder');
  placeholder.style.display = 'block';
}
/**
 *
 * @param number
 */
export function formatNumber(number) {
  return numeral(number).format('0.0a');
  // 'a' format will abbreviate numbers (e.g., 1000 as 1.0K)
}

/**
 *
 * @param message
 */
export function handleMessaging(message) {
  toast(message);
}

// A simple loading spinner component
/**
 *
 */
export function LoadingSpinner() {
  return (
    <>
      <main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
        <div className="main-content p-lg-3 border-start border-end">
          <div className="mb-5">
            <div className="feeds">
              <div className="spinner-border" role="status">

              </div>
              <span className="visually">Loading...</span>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
