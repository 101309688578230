import axios from 'axios';

const axiosClient = axios.create({baseURL: process.env.REACT_APP_USER_API_ENDPOINT});

const getToken = () => {
  axios.get(`/api/csrf-token`)
      .then((res) => {
        axiosClient.defaults.withCredentials = true;
        axiosClient.defaults.headers = {


          'Content-Type': 'application/json;charset=utf-8',
          'Accept': 'application/json',
          'x-csrf-Token': res.data.token,

        };
      })
      .catch((error) => console.error('error', error));
};

getToken();


/**
 *
 * @param  {object} URL   - The URL to perform the action with.
 * @return {object}  - object containign data
 */
export async function getUserReq(URL) {
  return await axiosClient.get(`/${URL}`);
}

/**
 *
 * @param  {object} URL   - The URL to perform the action with.
 * @param  {object} payload - submitted data
 * @return {object}  - object containign data
 */
export async function postUserReq(URL, payload) {
  return await axiosClient.post(`/${URL}`, payload);
}
