import React, {useState, useEffect, useContext} from 'react';
import {PropTypes} from 'prop-types';
import {getUserReq, postUserReq} from './Components/User/UserAxiosClient';
import {handleMessaging} from './Components/Common/Functions';
import axios from 'axios';
const AuthContext = React.createContext();

/**
 *
 */
export function useAuth() {
  return useContext(AuthContext);
}

/**
 *
 * @param props
 */
export function AuthProvider(props) {
  const userAgent = navigator.userAgent;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [_csrf, setCsrf] = useState('');
  const [messagesCount, setMessagesCount] = useState(0);

  const [currentuser, setCurrentuser] = useState({
    circle: [],
    followers: [],
    following: [],
  });


  const signUp = ( data) => {
  //  e.preventDefault();


    postUserReq('register', data)
        .then((res) => {
          if (res.status === 200) {
            setCurrentuser(res.data.user);
            setIsAuthenticated(true);

            window.location = '/editprofile';
          } else {
            handleMessaging(res.data.error);
          }
        })
        .catch((error) => {
          handleMessaging(error.message);
        });
  };


  const logIn = (data) => {
    postUserReq('login', data)
        .then((res) => {
          if (res.status === 200) {
            setCurrentuser( res.data.user );
            setIsAuthenticated(true);
            const [first] = currentuser?.onboarding;
            window.location = first || '/editprofile';
          } else {
            setCurrentuser({circle: [], followers: [], following: []});

            handleMessaging(res.data.error);
          }
        })
        .catch((error) => {
          handleMessaging(error.message);
        });
  };


  const handleGoogleLogin = () => {
    // Redirect to your Node.js/Express backend for Google authentication
    // window.location.href =

    // `${process.env.REACT_APP_USER_API_ENDPOINT}/auth/google`;

    // Make a GET request
    axios.get( `${process.env.REACT_APP_USER_API_ENDPOINT}/auth/google`)
        .then((res) => {
        // Handle the successful response
          //  console.log('Response:', res.data);
          setCurrentuser( res.data.user );
          // setPromotedUsers( res.data.promoted );
          // setMessages( res.data.messages );
          setIsAuthenticated(true);

          window.location = '/editprofile';
        })
        .catch((error) => {
        // Handle errors
          console.error('Error:', error);
        });
  };


  const logOut = (e) => {
    e.preventDefault();

    postUserReq('logout')
        .then((res) => {
          if (res.status === 200) {
            setIsAuthenticated(false);
            setCurrentuser({circle: [], followers: [], following: []});

            window.location.href = '/login';
          }
        })
        .catch((error) => {
          handleMessaging(error.message);
        });
  };


  async function isAuth() {
    getUserReq('getcurrentuser')
        .then((res) => {
          if (res.data.isAuth) {
            setIsAuthenticated(true);
            setCurrentuser( res.data.user );
          //  setPromotedUsers( res.data.promoted );
            // setMessages( res.data.messages );
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
  }

  async function getUnreadMessages() {
    getUserReq('getunreadmessages')
        .then((res) => {
          if (res.status === 200) {
            setMessagesCount( res.data.count );
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
  }

  const value = {
    currentuser,
    setCurrentuser,
    messagesCount,
    _csrf,
    isAuthenticated,
    setIsAuthenticated,
    handleGoogleLogin,
    signUp,
    logIn,
    logOut,
  };

  useEffect(() => {
    {isAuthenticated && getUnreadMessages();}

    isAuth();
  }, []);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

// PropTypes for the ConfirmBox component
AuthProvider.propTypes = {
  children: PropTypes.object,

};
