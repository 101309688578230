import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {AuthProvider} from './AuthContext';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 *
 * @param id
 * @param phase
 * @param actualDuration
 * @param baseDuration
 * @param startTime
 * @param commitTime
 */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>

      <App />

    </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
